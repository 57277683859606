function Footer() {
    return (
        <div id="footer">
            <span id='footer-title'>Copyright © 2022 Waldorfio&nbsp;&nbsp;&nbsp;
            </span>
        </div>
  );
}

export default Footer;
